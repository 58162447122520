<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('routes.payments') }}</h1>
        <el-select v-show="false" v-model="customerPickedId" size="mini" placement="end" filterable clearable>
          <el-option
            v-for="customer in customerFilter"
            :key="customer.id"
            :label="customer.name"
            :value="customer.id"
          />
        </el-select>
      </div>
      <p class="fw-bold">{{ pickedCustomer }}</p>
    </template>
    <template #dashboard>
      <div class="d-flex gap-4">
        <MonthlyPaymentOverview
          class="w-100"
          :loading="dashboardsLoading"
          :reconciliations="reconciliations"
          :payment-terms="paymentTerms"
          :payments-in-progress="paymentsInProgress"
          :customer="currentCustomer"
          :supplier="supplier"
        />
        <AnnualPaymentOverview
          class="w-50"
          :loading="dashboardsLoading"
          :reconciliations="reconciliations"
          :payment-terms="paymentTerms"
          :payments-in-progress="paymentsInProgress"
          :customer="currentCustomer"
          :supplier="supplier"
        />
      </div>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>
    <PaymentTable
      v-if="activeTab === 0"
      ref="paymentTable"
      :loading="loading"
      :supplier="supplier"
      :payment-imbalances="totalPaymentImbalances"
      :customer-id="customerPickedId"
      :is-admin="isAdmin"
      :to-close-modals="toCloseModals"
      :picked-reconciliation-id="pickedReconciliationId"
      @row-click="handlePaymentTableRowClick"
      @view-change="paginatedPaymentImbalances = $event"
      @reconciliation-modal-close="handleReconciliationModalClose"
    />
    <PaymentsInProgress v-if="activeTab === 1" :supplier="supplier" :customer-id="customerPickedId" />
    <PastPayments v-if="activeTab === 2" :supplier="supplier" :customer-id="customerPickedId" />
  </PageLayout>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil, prop, equals, omit } from 'ramda';
import Big from 'big.js';

import { PageLayout, Tabs } from '@/modules/core';
import { useUser, useTenancy } from '@/modules/auth';
import { useReconciliations } from '@/modules/reconciliation';

import { PAYMENT_STATUS } from '../types';

import {
  PaymentTable,
  MonthlyPaymentOverview,
  AnnualPaymentOverview,
  PastPayments,
  PaymentsInProgress,
} from './components';
import { usePayables, usePaymentTerms, usePaymentsBillingsInProgress } from '../compositions';

const mapImbalanceWithBalanceAndDueDateTerm = (reconciliations, payables, terms) => {
  const payablesBalanceByCustomer = payables.reduce((payablesMap, { customerId, balance }) => {
    payablesMap[customerId] = balance;
    return payablesMap;
  }, {});

  const termsByCustomer = terms.reduce((termsMap, term) => {
    termsMap[term.businessId] = term;
    return termsMap;
  }, {});

  const newImbalances = reconciliations.map((reconciliation) => {
    const { paymentDueDate, businessId, billedAmounts, paidAmounts } = reconciliation;
    const paymentTerm = termsByCustomer[businessId];
    const paymentStatuses = [];
    if (paymentTerm && Number.isInteger(paymentTerm.paymentDueNet)) {
      const currentDate = DateTime.local();
      const dueDate = DateTime.fromISO(paymentDueDate).toUTC();
      if (currentDate > dueDate) paymentStatuses.push(PAYMENT_STATUS.OVERDUE);
      else if (currentDate >= dueDate.minus({ days: 7 })) paymentStatuses.push(PAYMENT_STATUS.APPROACHING_DUE);
      else paymentStatuses.push(PAYMENT_STATUS.UNPAID);
    }

    const paidAmountsByBillingId = paidAmounts.reduce((paidMap, paidAmount) => {
      if (paidMap[paidAmount.billingId]) paidMap[paidAmount.billingId].push(paidAmount);
      else paidMap[paidAmount.billingId] = [paidAmount];
      return paidMap;
    }, {});

    const billingsLeftToBePaid = billedAmounts.reduce((billingsToPay, billedAmount) => {
      const totalPaidAmount =
        paidAmountsByBillingId[billedAmount.billingId]
          ?.reduce((sum, paidAmount) => sum.add(paidAmount.amount), new Big(0))
          .toNumber() ?? 0;

      const leftToBePaid = new Big(billedAmount.amount).abs().minus(new Big(totalPaidAmount).abs()).toNumber(); // could be credit

      if (leftToBePaid > 0)
        billingsToPay.push({
          ...billedAmount,
          balanceDue: new Big(leftToBePaid).times(Math.sign(billedAmount.amount)).toNumber(), // if credit should be negative
        });
      return billingsToPay;
    }, []);

    return {
      ...reconciliation,
      paymentTerm,
      paymentStatuses,
      totalBilledAmount: billedAmounts.reduce((acc, billedAmount) => acc + billedAmount.amount, 0),
      totalPaidAmount: paidAmounts.reduce((acc, paidAmount) => acc + paidAmount.amount, 0),
      payablesBalance: payablesBalanceByCustomer[businessId],
      billingsLeftToBePaid,
      overdue: [],
    };
  });

  const imbalancesByCustomer = newImbalances.reduce((imbalancesMap, imbalance) => {
    if (imbalancesMap[imbalance.businessId]) imbalancesMap[imbalance.businessId].push(imbalance);
    else imbalancesMap[imbalance.businessId] = [imbalance];
    return imbalancesMap;
  }, {});

  Object.entries(imbalancesByCustomer).forEach(([businessId, relatedImbalances]) => {
    imbalancesByCustomer[businessId] = relatedImbalances.sort(
      (a, b) => DateTime.fromISO(a.paymentDueDate) - DateTime.fromISO(b.paymentDueDate)
    );
  });

  return newImbalances.map((imbalance) => {
    const { businessId } = imbalance;
    const indexOfCurrentImbalance = imbalancesByCustomer[businessId].findIndex((i) => i.id === imbalance.id);
    const overdue = imbalancesByCustomer[businessId]
      .slice(0, indexOfCurrentImbalance)
      .filter((i) => i.paymentStatuses.includes(PAYMENT_STATUS.OVERDUE))
      .map(({ totalBilledAmount, totalPaidAmount, paymentDueDate }) => ({
        totalBilledAmount,
        paymentDueDate,
        totalUnpaidAmount: totalBilledAmount - totalPaidAmount,
      }));
    return { ...imbalance, overdue };
  });
};

export default {
  name: 'SupplierPayment',
  components: {
    PageLayout,
    Tabs,
    PaymentTable,
    MonthlyPaymentOverview,
    AnnualPaymentOverview,
    PastPayments,
    PaymentsInProgress,
  },
  props: { customerId: { type: String, default: null } },
  setup(props) {
    const { $router, $route, $i18n, $confirm } = getCurrentInstance().proxy;
    const customerPickedId = computed({
      set: (customerId) => {
        if (!customerId) $router.replace({ name: 'payments' });
        else $router.replace({ name: 'payments', params: { businessId: customerId } });
      },
      get: () => props.customerId,
    });
    const tenantId = ref($route.params.tenantId);
    const { currentTenant: supplier } = useTenancy();
    const { isAdmin } = useUser();

    const options = computed(() => ({ enabled: !!tenantId.value }));

    const {
      reconciliations: nonFilteredReconciliations,
      loading: reconciliationsLoading,
      refetch: refetchReconciliations,
    } = useReconciliations(
      computed(() => ({ supplierId: tenantId.value, closed: false })),
      options
    );

    const reconciliations = computed(() =>
      nonFilteredReconciliations.value.filter(
        ({ businessId }) => !customerPickedId.value || businessId === customerPickedId.value
      )
    );

    const {
      payables: nonFilteredPayables,
      loading: payablesLoading,
      refetch: refetchPayables,
    } = usePayables(
      computed(() => ({ supplierId: tenantId.value })),
      options
    );

    const payables = computed(() =>
      nonFilteredPayables.value.filter(
        ({ businessId }) => !customerPickedId.value || businessId === customerPickedId.value
      )
    );

    const {
      payments: allPaymentsInProgress,
      loading: paymentsInProgressLoading,
      refetch: refetchPaymentsInProgress,
    } = usePaymentsBillingsInProgress(
      computed(() => ({ supplierId: tenantId.value, completed: false, isCustomerSide: true })),
      options
    );

    const paymentsInProgress = computed(() =>
      allPaymentsInProgress.value
        .map((p) => ({ ...omit(['requestedDate'], p), date: p.date || p.requestedDate }))
        .filter((p) => !customerPickedId.value || p.businessId === customerPickedId.value)
    );

    const { terms: paymentTerms, loading: paymentTermsLoading } = usePaymentTerms(
      computed(() => ({ supplierId: tenantId.value })),
      options
    );

    const forceLoading = ref(false);

    const loading = computed(
      () => payablesLoading.value || reconciliationsLoading.value || paymentTermsLoading.value || forceLoading.value
    );

    const paymentImbalances = computed(() =>
      !loading.value
        ? mapImbalanceWithBalanceAndDueDateTerm(reconciliations.value, payables.value, paymentTerms.value)
        : []
    );

    const paginatedPaymentImbalances = ref([]);
    const dateRange = computed(() => {
      if (!paginatedPaymentImbalances.value.length) return null;

      let minDate;
      let maxDate;
      paginatedPaymentImbalances.value.forEach((pi) => {
        [...pi.billedAmounts.map(prop('date')), ...pi.unbilledOrders.map(prop('date'))].forEach((date) => {
          const jsDate = new Date(date);
          if (!minDate) minDate = jsDate;
          if (!maxDate) maxDate = jsDate;
          if (jsDate < minDate) minDate = jsDate;
          if (jsDate > maxDate) maxDate = jsDate;
        });
      });

      const toDate = DateTime.fromJSDate(maxDate);
      const fromDate =
        !customerPickedId.value && minDate < toDate.minus({ years: 1 }).toJSDate()
          ? toDate.minus({ years: 1 })
          : DateTime.fromJSDate(minDate);
      return {
        fromDate: fromDate.toFormat('yyyy-LL'),
        toDate: toDate.toFormat('yyyy-LL'),
      };
    });

    const reconciliationStatusVariables = ref({});

    watch(
      [dateRange, customerPickedId, supplier],
      (newValues, oldValues) => {
        if (equals(newValues, oldValues)) return;

        const [dateRangeNew, customerPickedIdNew, supplierNew] = newValues;

        reconciliationStatusVariables.value = reject(isNil)({
          ...dateRangeNew,
          supplierId: supplierNew.id,
          businessId: customerPickedIdNew,
        });
      },
      { immediate: true }
    );

    const toCloseModals = ref(false);

    let hourlyRefetchTimeout = null;

    const refetch = async () => {
      toCloseModals.value = true;
      forceLoading.value = true;
      await Promise.all([refetchPayables(), refetchReconciliations(), refetchPaymentsInProgress()]);
      forceLoading.value = false;
      hourlyRefetchTimeout = setHourRefreshTimeout();
    };

    const setHourRefreshTimeout = () =>
      setTimeout(() => {
        $confirm($i18n.t('payment.confirm.text'), $i18n.t('payment.confirm.title'), {
          type: 'warning',
          showClose: false,
          confirmButtonText: $i18n.t('payment.confirm.refresh'),
          cancelButtonText: $i18n.t('payment.confirm.notNow'),
          cancelButtonClass: 'el-button--secondary',
        })
          .then(refetch)
          .catch(() => (hourlyRefetchTimeout = setHourRefreshTimeout()));
      }, 3600 * 1000);

    onMounted(() => (hourlyRefetchTimeout = setHourRefreshTimeout()));
    onUnmounted(() => clearTimeout(hourlyRefetchTimeout));

    return {
      reconciliations,
      paymentTerms,
      supplier,
      customers: [],
      customerPickedId,
      activeTab: ref(0),
      totalPaymentImbalances: computed(() =>
        customerPickedId.value
          ? paymentImbalances.value.filter((i) => i.businessId === customerPickedId.value)
          : paymentImbalances.value
      ),
      loading,
      isAdmin,
      paginatedPaymentImbalances,
      toCloseModals,
      pickedReconciliationId: ref(null),
      dashboardsLoading: computed(
        () =>
          reconciliationsLoading.value ||
          paymentTermsLoading.value ||
          paymentsInProgressLoading.value ||
          forceLoading.value
      ),
      paymentsInProgress,
    };
  },
  computed: {
    customerFilter() {
      const customerFiltered = [...this.customers];
      return [
        { name: this.$t('payment.allCustomersFilter'), id: null },
        ...customerFiltered.sort((customerA, customerB) => customerA.name.localeCompare(customerB.name)),
      ];
    },
    currentCustomer() {
      return this.customerFilter.find(({ id }) => id === this.customerPickedId);
    },
    pickedCustomer() {
      return this.currentCustomer?.name;
    },
    tabs() {
      return [
        {
          text: this.$t('payment.paymentTable.paymentsForApproval'),
        },
        {
          text: this.$t('payment.paymentsInProgressTab.title'),
        },
        {
          text: this.$t('payment.paymentTable.executedPayments'),
        },
      ];
    },
  },
  methods: {
    handlePaymentTableRowClick({ id }) {
      this.pickedReconciliationId = id;
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
    },
  },
};
</script>
