<template>
  <div>
    <DashboardCard
      :loading="loading"
      :title="$t('payment.monthlyPaymentOverview.header', { businessName: customerName })"
      :action-type="CARD_ACTIONS.DATEPICKER"
      :date-picker-placeholder="$t('payment.monthlyPaymentOverview.datePicker.all')"
      :date-picker-label="$t('payment.monthlyPaymentOverview.fromDate')"
      :date-picker-type="'month'"
      :show-date-picker-shortcut-options="true"
      :clearable="true"
      @on-date-picker-date-change="datePickerDataChangeHandler"
    >
      <div class="w-100">
        <div
          v-if="totalForApproval.count === 0 && inProgress.count === 0 && paidPayments.count === 0"
          class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
        >
          <EmptyCalendarIllustration height="53.06px" width="53.56px" />
          <div class="text-typography-secondary mt-4">
            {{ $t('payment.monthlyPaymentOverview.noInvoicesForThisDate') }}
          </div>
        </div>
        <div v-else class="d-flex flex-row justify-content-between align-items-center content-wrapper mt-6">
          <div class="d-flex align-items-center">
            <DoughnutChart
              class="doughnut-chart-wrapper"
              :chunks="chartChunks"
              :height="100"
              :width="112"
              @tooltip-visible="doughnutInnerTextVisible = !$event"
            />
            <div class="me-3">
              <div class="d-flex flex-row align-items-center">
                <div>
                  <p>
                    {{ $t(`payment.monthlyPaymentOverview.${OVERVIEW_DATA_TYPE.TOTAL_FOR_APPROVAL}`) }}
                  </p>
                </div>
              </div>
              <div class="fw-bold my-1" style="font-size: 20px">
                {{ totalForApproval.total ? formatMoney(totalForApproval.total) : '-' }}
              </div>
              <div class="text-typography-secondary">
                <p class="bottom-text">
                  {{
                    totalForApproval.count
                      ? `${totalForApproval.count} ${$t('payment.monthlyPaymentOverview.invoices')}`
                      : '-'
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="px-5 line-seperator">
            <div class="d-flex flex-row align-items-center">
              <div>
                <p>
                  {{ $t(`payment.monthlyPaymentOverview.${OVERVIEW_DATA_TYPE.IN_PROGRESS}`) }}
                </p>
              </div>
            </div>
            <div class="fw-bold my-1" style="font-size: 20px">
              {{ inProgress.total ? formatMoney(inProgress.total) : '-' }}
            </div>
            <div class="text-typography-secondary">
              <p class="bottom-text">
                {{ inProgress.count ? `${inProgress.count} ${$t('payment.monthlyPaymentOverview.invoices')}` : '-' }}
              </p>
            </div>
          </div>

          <div class="px-5 line-seperator">
            <div class="d-flex flex-row align-items-center">
              <div>
                <p>
                  {{ $t(`payment.monthlyPaymentOverview.${OVERVIEW_DATA_TYPE.TOTAL}`) }}
                </p>
              </div>
            </div>

            <div class="fw-bold my-1" style="font-size: 20px">
              {{ paidPayments.total ? formatMoney(paidPayments.total) : '-' }}
            </div>
            <div class="text-typography-secondary">
              <p class="bottom-text">
                {{
                  paidPayments.count ? `${paidPayments.count} ${$t('payment.monthlyPaymentOverview.invoices')}` : '-'
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { uniq } from 'ramda';
import { DateTime } from 'luxon';

import { DashboardCard, CARD_ACTIONS } from '@/modules/core';

import { EmptyCalendarIllustration } from '@/assets/illustrations';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { DoughnutChart } from '../../components';
import { useBillingsByMonth, useBillingsGroupedByBusiness } from '../../compositions';
import { OVERVIEW_DATA_TYPE } from '../../types';

const getSumForImbalances = (imbalances) => imbalances.reduce((acc, { amount }) => acc + amount, 0);

const getSumsForInvoices = (reconciliations) => {
  const totalBilledAmount = reconciliations.reduce(
    (totalBilledAmount, { billedAmounts }) => totalBilledAmount + getSumForImbalances(billedAmounts),
    0
  );
  const totalPaidAmount = reconciliations.reduce(
    (totalPaidAmount, { paidAmounts }) => totalPaidAmount + getSumForImbalances(paidAmounts),
    0
  );

  const billingsCount = uniq(
    reconciliations
      .filter(({ billedAmounts }) => billedAmounts.length)
      .reduce((acc, { billedAmounts }) => acc.concat(billedAmounts.map(({ billingId }) => billingId)), [])
  ).length;
  const paidBillingsCount = uniq(
    reconciliations
      .filter(({ paidAmounts }) => paidAmounts.length)
      .reduce((acc, { paidAmounts }) => acc.concat(paidAmounts.map(({ billingId }) => billingId)), [])
  ).length;

  return {
    count: billingsCount - paidBillingsCount,
    total: totalBilledAmount - totalPaidAmount,
  };
};

export default {
  components: {
    DoughnutChart,
    EmptyCalendarIllustration,
    DashboardCard,
  },
  props: {
    loading: { type: Boolean, default: false },
    paymentsInProgress: { type: Array, default: () => [] },
    reconciliations: { type: Array, default: () => [] },
    paymentTerms: { type: Array, default: () => [] },
    customer: { type: Object, default: null },
    supplier: { type: Object, required: true },
  },
  setup(props) {
    const dateFilter = ref();
    const { formatToCurrency } = useCurrency();

    const { billings } = useBillingsByMonth(
      computed(() => ({
        supplierId: props.supplier.id,
        fromDate: dateFilter.value
          ? DateTime.fromISO(dateFilter.value).startOf('month').plus({ days: 1 }).toISODate()
          : null,
        toDate: dateFilter.value ? DateTime.fromISO(dateFilter.value).endOf('month').toISODate() : null,
      })),
      computed(() => ({ enabled: !!props.supplier.id && !!dateFilter.value }))
    );

    const { billingsAggregations } = useBillingsGroupedByBusiness(
      computed(() => ({ supplierId: props.supplier.id })),
      computed(() => ({ enabled: !!props.supplier.id }))
    );

    const datePickerDataChangeHandler = (monthPickerDate) => {
      if (!monthPickerDate) {
        dateFilter.value = null;
      } else {
        dateFilter.value =
          typeof monthPickerDate === 'string'
            ? monthPickerDate
            : DateTime.fromJSDate(monthPickerDate).toFormat('yyyy-MM');
      }
    };

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      OVERVIEW_DATA_TYPE,
      doughnutInnerTextVisible: ref(true),
      formatMoney,
      currentDate: ref(new Date()),
      dateFilter,
      billings,
      billingsAggregations,
      CARD_ACTIONS,
      datePickerDataChangeHandler,
    };
  },
  computed: {
    currentMonthUnpaidBillings() {
      return this.reconciliations
        .filter(
          ({ billedAmounts }) =>
            billedAmounts &&
            (!this.dateFilter ||
              billedAmounts.some(({ date }) => DateTime.fromISO(date).toFormat('yyyy-LL') === this.dateFilter))
        )
        .map((reconciliation) => ({
          ...reconciliation,
          paymentTerm: this.paymentTerms.find(({ businessId }) => businessId === reconciliation.businessId),
        }));
    },
    totalForApproval() {
      return getSumsForInvoices(this.currentMonthUnpaidBillings);
    },
    reconciliationsWithDueTerm() {
      return this.currentMonthUnpaidBillings.filter(
        ({ paymentTerm }) => paymentTerm && Number.isInteger(paymentTerm.paymentDueNet)
      );
    },
    withoutDueTerm() {
      const withoutDueTerm = this.currentMonthUnpaidBillings.filter(
        ({ paymentTerm }) => !paymentTerm || !Number.isInteger(paymentTerm.paymentDueNet)
      );
      return getSumsForInvoices(withoutDueTerm);
    },
    overdue() {
      const overdue = this.reconciliationsWithDueTerm.filter(
        ({ paymentDueDate }) => new Date(paymentDueDate) < this.currentDate
      );
      return getSumsForInvoices(overdue);
    },
    futurePayments() {
      const futurePayments = this.reconciliationsWithDueTerm.filter(
        ({ paymentDueDate }) => new Date(paymentDueDate) >= this.currentDate
      );
      return getSumsForInvoices(futurePayments);
    },
    inProgress() {
      const billingLinks = this.paymentsInProgress.reduce((links, { billingLinks }) => [...links, ...billingLinks], []);
      const relevantLinks = billingLinks.filter((link) => {
        return !this.dateFilter || this.billings.map(({ id }) => id).includes(link.billingId);
      });

      return {
        count: relevantLinks.length,
        total: relevantLinks.reduce((total, link) => total + link.amount, 0),
      };
    },
    invoicesTotal() {
      return {
        count: this.totalForApproval.count + this.inProgress.count,
        total: this.totalForApproval.total + this.inProgress.total,
      };
    },
    paidPayments() {
      const result = this.billingsAggregations?.group
        ?.filter((group) => !this.dateFilter || group.isoMonth === this.dateFilter)
        .filter((group) => !this.customer?.id || group.businessId === this.customer?.id)
        .reduce(
          (next, prev) => ({
            totalAmount: prev.totalAmount + next.totalAmount,
            count: prev.count + next.count,
          }),
          { totalAmount: 0, count: 0 }
        );
      return {
        count: result?.count - this.totalForApproval.count - this.inProgress.count ?? 0,
        total: result ? result.totalAmount / 100 - this.totalForApproval.total - this.inProgress.total : 0,
      };
    },
    chartChunks() {
      return this.reconciliations.length
        ? [
            {
              label: OVERVIEW_DATA_TYPE.WITHOUT_DUE_TERM,
              amount: this.withoutDueTerm.total,
              count: this.withoutDueTerm.count,
            },
            {
              label: OVERVIEW_DATA_TYPE.OVERDUE,
              amount: this.overdue.total,
              count: this.overdue.count,
              borderWidth: 20,
            },
            {
              label: OVERVIEW_DATA_TYPE.FUTURE_PAYMENTS,
              amount: this.futurePayments.total,
              count: this.futurePayments.count,
            },
          ]
        : [];
    },
    customerName() {
      return this.customer?.name;
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: 'long' }) : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .picker-small .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 136px;
}

.content-wrapper {
  position: relative;
  top: 13px;

  .bottom-text {
    font-size: 0.75rem !important;
  }
}

.doughnut-chart-wrapper {
  position: relative;
  bottom: 3px;
}

.line-seperator {
  [dir='ltr'] & {
    border-left: 1px solid #e6e8ea;
  }
  [dir='rtl'] & {
    border-right: 1px solid #e6e8ea;
  }
}
</style>
